 const hrSeoDescriptionEn = [
  "How to use biases in HR teams",
  "How HR can use biases",
  "Nudging in HR using cognitive biases",
  "Using cognitive biases in companies",
  "Using cognitive biases in products",
  "Cognitive science and HR teams",
  "Science-based guide for HR",
  "How to become better HR",
  "How to enhance HR skills",
  "Best tooltips for HR"
];

export  default  hrSeoDescriptionEn;
