const hrSeoDescriptionRu = [
  "Как использовать когнитивные искажения в HR",
  "Как HR может использовать когнитивные искажения",
  "Наддувание в HR с использованием когнитивных искажений",
  "Использование когнитивных искажений в компаниях",
  "Использование когнитивных искажений в продуктах",
  "Когнитивная наука и HR-команды",
  "Научный путеводитель для HR",
  "Как стать лучшим HR",
  "Как улучшить навыки HR",
  "Лучшие подсказки для HR"

]

export default  hrSeoDescriptionRu;
