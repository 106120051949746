const UXCoreIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0221 6V4C12.8131 4 9.79608 5.248 7.52708 7.514C5.25708 9.78 4.00708 12.794 4.00708 15.999C4.00708 19.204 5.25708 22.217 7.52708 24.485C9.79708 26.75 12.8141 27.998 16.0221 27.998V25.998C13.3471 25.998 10.8321 24.958 8.94008 23.069C7.04908 21.179 6.00708 18.668 6.00708 15.998C6.00708 13.327 7.04908 10.817 8.94008 8.928C10.8311 7.04 13.3461 6 16.0221 6Z"
      fill="#515151"
    />
    <path
      d="M16.0219 20.999C18.7872 20.999 21.0289 18.7604 21.0289 15.999C21.0289 13.2376 18.7872 10.999 16.0219 10.999C13.2566 10.999 11.0149 13.2376 11.0149 15.999C11.0149 18.7604 13.2566 20.999 16.0219 20.999Z"
      fill="#515151"
    />
    <path
      d="M30.006 17.014C29.808 19.739 28.824 22.327 27.163 24.499C25.501 26.671 23.258 28.299 20.675 29.205C18.095 30.112 15.326 30.247 12.666 29.593C10.008 28.939 7.61796 27.535 5.75396 25.533C3.88996 23.532 2.66196 21.051 2.20096 18.357C1.73996 15.664 2.07396 12.916 3.16696 10.41C4.25996 7.904 6.04796 5.788 8.33696 4.291C10.628 2.792 13.285 2 16.022 2V0C12.895 0 9.85896 0.905 7.24296 2.616C4.62696 4.327 2.58396 6.745 1.33496 9.609C0.0849631 12.473 -0.296037 15.614 0.229963 18.693C0.755963 21.772 2.16096 24.608 4.29096 26.895C6.42096 29.182 9.15196 30.786 12.189 31.533C13.454 31.845 14.741 32 16.026 32C17.825 32 19.619 31.695 21.339 31.091C24.289 30.056 26.852 28.196 28.751 25.713C30.65 23.232 31.774 20.274 32 17.158L30.006 17.014Z"
      fill="#515151"
    />
    <path
      d="M31.8879 13.783C31.4259 10.479 29.9699 7.449 27.6759 5.021L26.2229 6.394C28.2289 8.518 29.5029 11.169 29.9079 14.06L31.8879 13.783Z"
      fill="#515151"
    />
    <path
      d="M26.1701 3.61903C25.6101 3.16203 25.0171 2.74103 24.4061 2.36603L23.3611 4.07103C23.8951 4.39903 24.4141 4.76803 24.9041 5.16803L26.1701 3.61903Z"
      fill="#515151"
    />
  </svg>
);

export default UXCoreIcon;
